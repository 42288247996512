.category-playlists {
  padding: 20px;
  width: 800px;
  margin: 0 auto;
}

.category-playlists-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
}

/* 左侧分类部分 */
.categories-section {
  flex: 0 0 300px;
  border-right: 1px solid #eee;
  padding-right: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
}

.category-group {
  margin-bottom: 20px;
}

.group-title {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  padding-left: 10px;
}

.categories-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.category-button {
  padding: 6px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #000;
}

.category-button:hover {
  background: #f5f5f5;
}

.category-button.active {
  background: #31c27c;
  color: #fff;
  border-color: #31c27c;
}

/* 右侧歌单部分 */
.playlists-section {
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  max-width: 500px;
}

.playlists-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 10px;
}

.playlist-item {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.playlist-item:hover {
  transform: translateY(-5px);
}

.playlist-cover {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.playlist-cover img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.playlist-play-count {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
}

.playlist-details {
  padding: 12px;
}

.playlist-title {
  font-size: 14px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.playlist-id {
  font-size: 12px;
  color: #999;
  margin: 4px 0;
}

.playlist-creator {
  font-size: 12px;
  color: #666;
  margin: 4px 0;
}

.playlist-desc {
  font-size: 12px;
  color: #666;
  margin: 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 限制显示 3 行 */
  -webkit-box-orient: vertical;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  padding: 20px 0;
}

.pagination-button {
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  color: #000;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: #000;
}

.current-page {
  color: #666;
}

.loading {
  text-align: center;
  color: #1890ff;
  padding: 40px;
}

.error-message {
  color: #ff4d4f;
  padding: 12px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  text-align: center;
  margin: 20px 0;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
}

.pagination button {
  padding: 8px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.3s;
}

.pagination button:disabled {
  background-color: #f5f5f5;
  color: #d9d9d9;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  border-color: #1890ff;
  color: #1890ff;
}

.page-number {
  color: #666;
} 