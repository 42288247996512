.album-detail {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.album-header {
  display: flex;
  gap: 24px;
  margin-bottom: 32px;
  padding: 20px;
  background: #f8f8f8;
  border-radius: 8px;
  min-height: 400px;
}

.album-cover {
  flex-shrink: 0;
}

.album-cover img {
  width: 300px;
  height: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.album-info {
  flex: 1;
}

.album-name {
  font-size: 28px;
  color: #333;
  margin: 0 0 16px 0;
}

.album-meta {
  margin: 16px 0;
  color: #666;
}

.album-meta p {
  margin: 8px 0;
}

.album-desc {
  margin-top: 20px;
}

.album-desc h3 {
  margin: 0 0 12px 0;
  font-size: 16px;
  color: #333;
}

.album-desc p {
  white-space: pre-wrap;
  line-height: 1.6;
}

.album-songs {
  margin-top: 40px;
}

.album-songs h2 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #333;
}

.song-list {
  margin-top: 32px;
}

.song-item {
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #eee;
}

.song-index {
  width: 40px;
  color: #999;
  text-align: center;
}

.song-info {
  flex: 1;
  margin: 0 16px;
}

.song-name {
  font-weight: 500;
}

.song-artist {
  font-size: 14px;
  color: #666;
}

.song-duration {
  color: #999;
  margin-right: 16px;
}

.vip-tag {
  background: #ff4d4f;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.unplayable-msg {
  color: #ff4d4f;
  font-size: 12px;
  margin-left: 8px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 12px;
  padding: 20px 0;
}

.pagination button {
  padding: 8px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: white;
  color: #000;
  cursor: pointer;
}

.pagination button:hover {
  background: #f5f5f5;
}

.pagination button:disabled {
  background: #f5f5f5;
  color: #d9d9d9;
  cursor: not-allowed;
}

.pagination span {
  color: #666;
}

.loading {
  text-align: center;
  color: #1890ff;
  padding: 40px;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 40px;
}

.error-message {
  color: #ff4d4f;
  padding: 12px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  text-align: center;
}

.retry-button {
  padding: 8px 16px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.retry-button:hover {
  background-color: #40a9ff;
}

.album-detail-container {
  padding: 20px;
}

.input-section {
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  gap: 10px;
}

.album-id-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
}

.fetch-button {
  padding: 8px 16px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.fetch-button:disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
} 