.user-profile {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.not-logged-in {
  text-align: center;
  color: #666;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.user-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.avatar-container {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0;
}

.user-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  color: #999;
  background-color: #e8e8e8;
}

.user-details {
  text-align: center;
}

.nickname {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.loading {
  text-align: center;
  color: #1890ff;
  padding: 20px;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.error-message {
  color: #ff4d4f;
  padding: 12px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}

.refresh-button {
  padding: 8px 16px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.refresh-button:hover {
  background-color: #40a9ff;
} 