.vip-info {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.not-logged-in {
  text-align: center;
  color: #666;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.vip-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.vip-status {
  display: flex;
  align-items: center;
  gap: 12px;
}

.status-label {
  font-size: 16px;
  color: #666;
}

.status-value {
  font-size: 16px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 4px;
}

.status-value.is-vip {
  color: #ff9c00;
  background-color: #fff7e6;
  border: 1px solid #ffd591;
}

.status-value.not-vip {
  color: #666;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
}

.vip-period {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  background-color: #fafafa;
  border-radius: 4px;
}

.period-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.period-label {
  color: #666;
  min-width: 80px;
}

.period-value {
  color: #333;
}

.loading {
  text-align: center;
  color: #1890ff;
  padding: 20px;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.error-message {
  color: #ff4d4f;
  padding: 12px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}

.refresh-button {
  padding: 8px 16px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: center;
}

.refresh-button:hover {
  background-color: #40a9ff;
} 