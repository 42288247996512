.song-info-batch {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.input-section {
  margin: 20px 0;
}

.input-group {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.song-ids-input {
  flex: 1;
  min-height: 38px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  resize: vertical;
}

.fetch-button {
  white-space: nowrap;
  padding: 8px 16px;
  height: 38px;
  border: none;
  border-radius: 4px;
  background-color: #1890ff;
  color: white;
  cursor: pointer;
}

.fetch-button:disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
}

.fetch-button:hover:not(:disabled) {
  background-color: #40a9ff;
}

.songs-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.song-item {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.song-cover {
  width: 64px;
  height: 64px;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}

.song-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.song-details {
  flex: 1;
  min-width: 0;
}

.song-name {
  margin: 0 0 4px 0;
  font-size: 16px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.song-artist,
.song-album {
  margin: 0;
  font-size: 14px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.song-permission {
  flex-shrink: 0;
}

.permission-badge {
  display: inline-block;
  padding: 4px 8px;
  background-color: #52c41a;
  color: white;
  border-radius: 4px;
  font-size: 12px;
}

.loading {
  text-align: center;
  color: #1890ff;
  padding: 40px;
}

.error-message {
  color: #ff4d4f;
  padding: 12px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  margin-top: 12px;
} 