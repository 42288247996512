.qq-music-login {
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.login-container {
  margin-top: 20px;
}

.qrcode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.qrcode-container img {
  width: 200px;
  height: 200px;
  border: 1px solid #e8e8e8;
  padding: 10px;
  border-radius: 8px;
}

.scan-tip {
  color: #666;
  margin: 0;
}

.loading {
  color: #1890ff;
  margin: 20px 0;
}

.success-message {
  color: #52c41a;
  padding: 16px;
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  border-radius: 4px;
  margin-top: 20px;
}

.error-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.error-message {
  color: #ff4d4f;
  padding: 12px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
}

button {
  padding: 12px 24px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #40a9ff;
} 