.scene-category-container {
  padding: 20px;
}

.scene-category-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.scene-category-item {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
}

.scene-category-item h3 {
  margin-bottom: 15px;
  color: #333;
}

.sub-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.sub-category-link {
  text-decoration: none;
  color: #666;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 15px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.sub-category-link:hover {
  background-color: #1db954;
  color: white;
}

.scene-category-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.scene-category-item {
  border: 1px solid #eee;
  padding: 15px;
  border-radius: 8px;
}

.sub-categories-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sub-category-link {
  text-decoration: none;
  color: #333;
  padding: 8px;
  border-radius: 4px;
}

.sub-category-link:hover {
  background-color: #f5f5f5;
} 