.music-search {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.search-header {
  margin-bottom: 20px;
}

.search-input-container {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  border: 2px solid #e8e8e8;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s;
}

.search-input:focus {
  border-color: #1890ff;
}

.search-type-selector {
  display: flex;
  gap: 12px;
}

.type-button {
  padding: 8px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.3s;
}

.type-button.active {
  background: #1890ff;
  color: white;
  border-color: #1890ff;
}

.type-button:hover:not(.active) {
  border-color: #1890ff;
  color: #1890ff;
}

.search-results {
  margin-top: 20px;
}

.result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
}

.result-item:hover {
  background-color: #f5f5f5;
}

.song-info, .album-info, .playlist-info {
  flex: 1;
}

.song-info h3, .album-info h3, .playlist-info h3 {
  margin: 0 0 5px 0;
  font-size: 16px;
}

.song-info p, .album-info p, .playlist-info p {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.song-meta {
  display: flex;
  align-items: center;
  gap: 10px;
}

.duration {
  color: #666;
  font-size: 14px;
}

.unplayable-tag {
  color: #ff4d4f;
  font-size: 12px;
}

.album-info {
  display: flex;
  gap: 15px;
}

.album-cover {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.loading {
  text-align: center;
  color: #1890ff;
  padding: 20px;
}

.error-message {
  color: #ff4d4f;
  padding: 12px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  text-align: center;
  margin: 20px 0;
}

.no-results {
  text-align: center;
  color: #666;
  padding: 40px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding: 15px;
}

.page-button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s;
}

.page-button:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.6;
}

.page-button:not(:disabled):hover {
  background-color: #f0f0f0;
}

.page-info {
  color: #666;
}

.search-button {
  padding: 8px 16px;
  margin-left: 8px;
  background-color: #1db954;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.search-button:hover {
  background-color: #1ed760;
}

.playback-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 8px;
}

.url-button {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #1890ff;
  color: white;
}

.url-button.disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
}

.url-button a {
  color: white;
  text-decoration: none;
}

.url-button.disabled span {
  color: rgba(0, 0, 0, 0.25);
} 