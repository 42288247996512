.scene-songlist {
  padding: 20px;
}

.scene-songlist h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.scene-songlist-subtitle {
  color: #666;
  margin-bottom: 20px;
}

.scene-songlist-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  padding: 16px;
}

.scene-songlist-item {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px;
  margin: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
  cursor: pointer;
}

.scene-songlist-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.scene-songlist-item img {
  width: 100%;
  border-radius: 8px;
}

.scene-songlist-item-title {
  margin-top: 8px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scene-songlist-item-singer {
  font-size: 0.9em;
  color: #666;
  margin-top: 4px;
}

.scene-songlist-header {
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.scene-songlist-header h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.scene-songlist-info {
  margin-top: 8px;
  color: #666;
}

.scene-songlist-subtitle {
  margin-right: 16px;
}

.scene-songlist-count {
  font-size: 14px;
}

.song-cover {
  width: 100%;
  position: relative;
  margin-bottom: 12px;
}

.song-cover img {
  width: 100%;
  border-radius: 4px;
  aspect-ratio: 1;
  object-fit: cover;
}

.song-info {
  padding: 0 8px;
}

.song-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.song-artist, .song-album {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.song-meta {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  font-size: 12px;
}

.vip-badge {
  background-color: #ff4d4f;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.try-badge {
  background-color: #52c41a;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.duration {
  color: #999;
}