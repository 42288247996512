.long-audio-categories {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.categories-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.categories-header h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.refresh-button {
  padding: 8px 16px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.refresh-button:hover {
  background-color: #40a9ff;
}

.categories-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.category-section {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.category-title {
  margin: 0 0 16px 0;
  font-size: 20px;
  color: #333;
  padding-bottom: 12px;
  border-bottom: 1px solid #f0f0f0;
}

.subcategories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.subcategory-card {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 16px;
  transition: all 0.3s;
  cursor: pointer;
}

.subcategory-card:hover {
  background: #e6f7ff;
  transform: translateY(-2px);
}

.subcategory-title {
  margin: 0;
  font-size: 16px;
  color: #333;
  text-align: center;
}

.loading {
  text-align: center;
  color: #1890ff;
  padding: 40px;
  font-size: 16px;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 40px;
}

.error-message {
  color: #ff4d4f;
  padding: 12px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  text-align: center;
}

.retry-button {
  padding: 8px 16px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.retry-button:hover {
  background-color: #40a9ff;
}

.no-categories {
  text-align: center;
  color: #666;
  padding: 40px;
  background-color: #f5f5f5;
  border-radius: 8px;
} 